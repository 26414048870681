import React, { useState, useEffect } from "react";

// Css
import "bootstrap/dist/css/bootstrap.min.css";
import './sass/core.scss';
import './assets/css/responsive.css';

// React Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import "./i18n/i18n";
import i18n from "i18next";

// Component
import Loading from "./components/Loading";
import Footer from "./components/Footer";
import WhatsappICON from "./components/WhatsappICON";

// Icons
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  document.dir = i18n.dir();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  const [isActiveHeader, setIsActiveHeader] = useState(false);
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const handleSlider = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const handleClickItem = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const media = [
    {
      social: "facebook",
      link: "https://www.facebook.com/voipforafrica20",
      icon: <FaFacebookF />,
    },
    {
      social: "instagram",
      link: "https://www.instagram.com/voip.forafrica?fbclid=IwAR3QFNmnYjDmF5whsbjROkGPjBtSJnOejvvRNsHNGeLOH0AG_PbSv7YUHh4",
      icon: <FaInstagram />,
    },
    {
      social: "whatsapp",
      link: "https://api.whatsapp.com/send/?phone=212664217576",
      icon: <FaWhatsapp />,
    },
    {
      social: "linkedinIn",
      link: "https://www.linkedin.com/company/96210774/admin/feed/posts/",
      icon: <FaLinkedinIn />,
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        <Route
          index
          element={
            <Home
              media={media}
              isActiveHeader={isActiveHeader}
              setIsActiveHeader={setIsActiveHeader}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer media={media} />

      {/* Whatsapp ICON */}
      <WhatsappICON media={media[2].link} />

      {/* Toast */}
      <ToastContainer />
    </Router>
  );
};

export default App;
