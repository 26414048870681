import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subscription from "../components/Subscription";
// I18n react init
import { useTranslation } from "react-i18next";

const Footer = ({ media }) => {
  const { i18n, t } = useTranslation();
  const [copyrightDate, setCopyrightDate] = useState("");

  useEffect(() => {
    setCopyrightDate(new Date().getFullYear());
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* <div className="single_footer single_footer_address">
            <h4 className="">{t(`footer.title`)}</h4>
            <div className="signup_form ">
              <Subscription />
            </div>
          </div> */}
          <div className="social_profile">
            <ul>
              {media &&
                media.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link} target="_blank">
                      {item.icon}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {/* END ROW */}
        <div className="row">
          <div className="col-lg-12 col-sm-12 ">
            <p className="copyright">
              <a
                href="https://www.bigwalltechnology.com/"
                className="text-white"
                target="_blank"
              >
                © Copyright {copyrightDate} VIOP FOR AFRICA. Tous droits
                réservés - Site réalisé par : BIGWALL TECHNOLOGY
              </a>
            </p>
          </div>
          {/* END COL */}
        </div>
        {/* END ROW */}
      </div>
      {/* END CONTAINER */}
    </footer>
  );
};

export default Footer;
