import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from 'react-toastify';

const PartnerForm = () => {
  const { t } = useTranslation();

  const initialFormData = {
    vousEtes: "",
    centreAppels: "",
    baseOu: "",
    activite: "",
    nomGerant: "",
    solutionInteresse: "",
    contact: "",
  };

  const initialErrorMessages = {
    contact: "",
  };

  // State variables
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState(initialErrorMessages);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate input and update error messages
    if (name === "contact" && !/^\d+$/.test(value)) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [name]: t("partners.form.validation.contact"),
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    try {
      const res = await axios.post("https://voip-for-africa-backend.onrender.com/sendEmail", formData);

      if (res.data) {
        setIsSubmitting(false)
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setIsSubmitting(false)
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>{t(`partners.form.vousEtes`)}</label>
                <input
                  type="text"
                  name="vousEtes"
                  className="form-control"
                  value={formData.vousEtes}
                  onChange={handleChange}
                  required
                  placeholder="Jhon shon"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>{t(`partners.form.centreAppels`)}</label>
                <input
                  type="text"
                  name="centreAppels"
                  className="form-control"
                  value={formData.centreAppels}
                  onChange={handleChange}
                  required
                  placeholder="call center"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>{t(`partners.form.baseOu`)}</label>
                <input
                  type="text"
                  name="baseOu"
                  className="form-control"
                  value={formData.baseOu}
                  onChange={handleChange}
                  required
                  placeholder="Marrakech"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>{t(`partners.form.email`)}</label>
                <input
                  type="text"
                  name="email"
                  className={`form-control ${errorMessages.email ? "is-invalid" : ""
                    }`}
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="example@domain.com"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>{t(`partners.form.nomGerant`)}</label>
                <input
                  type="text"
                  name="nomGerant"
                  className="form-control"
                  value={formData.nomGerant}
                  onChange={handleChange}
                  required
                  placeholder="Jhon Doe"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>{t(`partners.form.contact`)}</label>
                <input
                  type="text"
                  name="contact"
                  className={`form-control ${errorMessages.contact ? "is-invalid" : ""
                    }`}
                  value={formData.contact}
                  onChange={handleChange}
                  required
                  placeholder="0600000000"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>{t(`partners.form.activite`)}</label>
                <input
                  type="text"
                  name="activite"
                  className="form-control"
                  value={formData.activite}
                  onChange={handleChange}
                  required
                  placeholder="..."
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>{t(`partners.form.solutionInteresse`)}</label>
                <textarea
                  name="solutionInteresse"
                  required
                  onChange={handleChange}
                  id=""
                  cols="20"
                  rows="10"
                  placeholder=""
                ></textarea>

              </div>
            </div>
          </div>

          <button
            type="submit"
            className={`button`}
          >
            {isSubmitting
              ? t(`partners.form.button.submitting`)
              : t(`partners.form.button.send`)}
          </button>
        </form>
      </div>
    </>
  );
};

export default PartnerForm;
