import React, { useEffect, useState } from "react";
import waICON from "../assets/images/whatsapp.svg";
import { Link } from "react-router-dom";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";
import { useTranslation } from "react-i18next";

const WhatsappICON = ({ media }) => {
  const { i18n, t } = useTranslation();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 200) {
        setIsActive(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means the effect runs once after the initial render

  return (
    <div id="wa" className="position-relative">
      <Link to={media} target="_blank">
        <figure className={`wa-icon ${isActive ? "active" : ""}`}>
          <LazyLoadImageWrapper src={waICON} alt={"Tapisserie De Rêve"} />
        </figure>
        <p className={`wa-txt ${isActive ? "active" : ""}`}>
          {t(`have_a_question`)}
        </p>
      </Link>
    </div>
  );
};

export default WhatsappICON;
