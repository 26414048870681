import React, { useEffect } from "react";
import $ from "jquery";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";
import videPlayerICON from "../assets/images/video/player-btn.svg";
import video from "../assets/video/1.mp4";

const MagnificPopup = () => {
  useEffect(() => {
    // Initialize Magnific Popup
    $(".play-btn").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }, []);

  return (
    <a href={video} className="play-btn">
      <img src={videPlayerICON} alt="videoIcon" />
    </a>
  );
};

export default MagnificPopup;

