import React, { useEffect, useState } from "react";
import img from "../assets/images/scroll-up/scroll-up.gif";

const ScrollUp = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // You can adjust the scroll threshold based on your preference
      if (scrollY > 100) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    const handleScrollUp = () => {
      window.scroll(0, 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isActive]);

  return (
    <div
      id="scrollUp"
      className={isActive ? "active" : ""}
      onClick={() => isActive && window.scroll(0, 0)}
    >
      <figure className="image">
        <img src={img} alt="" />
      </figure>
    </div>
  );
};

export default ScrollUp;
