import React, { useEffect, useState } from "react";
// use react icons
import { IoLanguage, IoChevronDownOutline, IoClose } from "react-icons/io5";

// Import Images
import logo from "../assets/images/logo/hero-logo.svg";
import logoMobile from "../assets/images/logo/icon.svg";
import videoImage from "../assets/images/video/1.png";
import About1 from "../assets/images/about/1.png";
import About2 from "../assets/images/about/2.png";

// I18n react init
import { LANGUAGES } from "../i18n/index";
import { useTranslation } from "react-i18next";

// Import Components
import Cursor from "../components/Cursor";
import ScrollUp from "../components/ScrollUp";
import MagnificPopup from "../components/MagnificPopup";
import PartnerForm from "../components/PartnerForm";
import ServiceCards from "../components/ServiceCards";
import { Link } from "react-router-dom";

const Home = ({ media }) => {
  const { i18n, t } = useTranslation();

  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const [isLang, setIsLang] = useState(false);
  const handleLang = () => {
    setIsLang(!isLang);
  };

  const handleSlider = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const Pages = ["home", "about", "services", "partners"];

  const [isActiveHeader, setIsActiveHeader] = useState(false);

  const [activeSection, setActiveSection] = useState("home");


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 120) {
        setIsActiveHeader(true);
      } else if (scrollPosition <= 120) {
        setIsActiveHeader(false);
      }

      // Determine the active section based on scroll position
      const sections = ["home", "about", "services", "partners"];
      const active = sections.find((section) => {
        const element = document.getElementById(section);
        // console.log("element => ", element);
        if (element) {
          const offsetTop = element.offsetTop;
          const offsetBottom = offsetTop + element.offsetHeight - 600;

          return scrollPosition >= offsetTop && scrollPosition < offsetBottom;
        }
        return false;
      });

      // Update the state with the active section
      if (active !== activeSection) {
        setActiveSection(active);
      }
    };

    window.addEventListener("scroll", handleScroll);


    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  const handleClickItem = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  return (
    <>
      <Cursor />

      {/* header */}
      <header id="header" className={`${isActiveHeader ? "active" : ""}`}>
        <div className="lr-container">
          <div className="brand-logo">
            <img className="desktop-logo" src={logo} alt="" />
            <img className="mobile-logo" src={logoMobile} alt="" />
          </div>
          <nav className="navbar-nav">
            <ul className="nav-items">
              {Pages.map((page, index) => (
                <li
                  className={`nav-item smooth-menu ${activeSection === page ? "active" : ""
                    }`}
                  key={index}
                >
                  <a href={`#${page}`}>{t(`navbar.${page}`)}</a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="nav-footer-btns">
            {/* Social Media */}
            <div className="social_profile">
              <ul>
                {media &&
                  media.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link} target="_blank">
                        {item.icon}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>

            {/* drop-down-language-menu */}
            <div className="drop-down-language-menu" onClick={handleLang}>
              <div className="dropdown language-btn">
                <span className="icon is-small">
                  <IoLanguage size={20} color="#33a2d4" />
                </span>
                <span className="language-hidden-in-mobile">
                  {t("navbar.language")}
                </span>
                <span className="icon is-small">
                  <IoChevronDownOutline size={20} color="#777" />
                </span>
              </div>
              <div className={`dropdown-menu-lg ${isLang ? "active" : ""}`}>
                {LANGUAGES.map((language, index) => (
                  <div
                    className={`dropdown-item ${language.code}-button`}
                    data-lang={language.code}
                    key={index}
                    onClick={() => i18n.changeLanguage(language.code)}
                  >
                    {language.label}
                  </div>
                ))}
              </div>
            </div>

            {/* burger-icon */}
            <div
              className="burger-icon"
              id="burger-icon"
              onClick={handleSlider}
            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 32 42"
                xmlns="http://www.w3.org/2000/svg"
                className={`${isSidebarActive ? "active" : ""}`}
              >
                <g transform="matrix(1,0,0,1,-389.5,-264.004)">
                  <g id="arrow_left2">
                    <g transform="matrix(1,0,0,1,0,5)">
                      <path
                        id="top"
                        d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                      />
                    </g>
                    <g transform="matrix(1,1.22465e-16,1.22465e-16,-1,0.00024296,564.935)">
                      <path
                        id="bottom"
                        d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                      />
                    </g>
                    <path id="middle" d="M390,284.967L420,284.967" />
                  </g>
                </g>
              </svg>
            </div>


          </div>
        </div>
      </header>

      {/* Dark background */}
      <div
        className={`bg---dark ${isSidebarActive ? "active" : ""}`}
        onClick={handleSlider}
      ></div>

      {/* Sidebar */}
      <div
        className={`sidebar ${isSidebarActive ? "active" : ""}`}
        id="sidebar"
      >
        <a className="res-cross" id="res-cross" onClick={handleSlider}>
          <IoClose color="white" />
        </a>
        <div className="side-links my-5">
          {Pages.map((page, index) => (
            <a
              href={`#${page}`}
              key={index}
              className="nav-item smooth-menu"
              onClick={handleClickItem}
            >
              {t(`navbar.${page}`)}
            </a>
          ))}
        </div>
      </div>

      <section id="home" className="hero-section default-padding">
        <div className="lr-container">
          <main>
            <h1 className="hero-main-title">{t(`hero.main-title`)}</h1>
            <h1 className="hero-title">{t(`hero.title`)}</h1>
            <p className="hero-desc">{t(`hero.description`)}</p>
            <div
              className="d-flex buttons"
              style={{ margin: "30px 0px", gap: "2em" }}
            >
              <a href="#services" className="custom-btn bx-btn">
                <span>{t(`hero.button`)}</span>
              </a>
              <MagnificPopup />
            </div>
          </main>
        </div>
      </section>

      <section id="about" className="default-padding mt-lg-5">
        <div className="lr-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 order-md-1 order-2 ">
              <main>
                <h2 className="section-title">{t(`about.title`)}</h2>
                <div className="about-content">
                  <p>
                    <span className="text-bold text-custom text-uppercase">
                      {t(`about.content.company-name`)}
                    </span>
                    {t(`about.content.paragraph1`)}
                  </p>
                  <p>
                    {t(`about.content.paragraph2.style-normal`)}
                    <span className="text-bold">
                      {t(`about.content.paragraph2.bold`)}
                    </span>
                  </p>
                  <p>{t(`about.content.paragraph2.style-normal2`)}</p>
                </div>
                <div className="about-btn">
                  <a href="#partners" className="custom-btn bx-btn">
                    <span>{t(`about.content.button`)}</span>
                  </a>
                </div>
              </main>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <figure>
                <img src={About2} alt="lorem" className="" />
                <img src={About1} alt="lorem" className="" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="default-padding">
        <div className="lr-container">
          <h2 className="section-title text-center">{t(`services.title`)}</h2>
          <p className="section-description text-center"></p>
          <ServiceCards />
        </div>
      </section>

      <section id="video" className="default-padding-top ">
        <figure>
          <img src={videoImage} alt="" />
          <MagnificPopup />
        </figure>
      </section>

      <section id="partners" className="default-padding">
        <div className="lr-container">
          <h2 className="section-title text-center ">{t(`partners.title`)}</h2>
          <p className="section-description text-center ">
            {t(`partners.description`)}
          </p>
          <PartnerForm />
        </div>
      </section>

      <ScrollUp />
    </>
  );
};

export default Home;
