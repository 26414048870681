import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/*********************************************
/* Company: BIGWALL TECHNOLOGY
/* Author: Achraf LAFKIRI Full stack developer
/* Version: 1.1.0
/* Author Portfolio: https://www.lafkiri.com/
/* Company Site: https://bigwalltechnology.com/
/* Site: https://www.voip4africa.com/
/********************************************/
