import React from "react";
import img from "../assets/images/logo/icon.svg";

const Loading = () => {
  return (
    <div className="loading-screen">
      <div className="logo">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default Loading;
