import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import services from "../data/Services";
// I18n react init
import { useTranslation } from "react-i18next";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

const ServiceCards = () => {
    const { i18n, t } = useTranslation();
    return (
        <div className="service-gridx">
            {services.map((item, index) => (
                <div className="service-card" key={index}>
                    <div className="service-header">
                        <div className="icon">
                           <FontAwesomeIcon icon={item.icon} />
                        </div>
                    </div>
                    <div className="service-body">
                        <h4 className="card-title">{i18n.language === "fr" ? item.titleFr : item.titleEn}</h4>
                        <p className="card-body">{i18n.language === "fr" ? item.descFr : item.descEn}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServiceCards;
