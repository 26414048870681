import { faPhone, faCogs, faLifeRing, faBullhorn, faRobot, faTools, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const services = [
    {
        titleFr: "trunk sip",
        titleEn: "SIP Trunk",
        descFr: "Routes d’appels toute destination meilleur rapport qualité prix",
        descEn: "Routes calls to any destination with the best quality-to-price ratio.",
        icon: faPhoneAlt,
    },
    {
        titleFr: "Logiciel Centre d'Appels",
        titleEn: "Call Center Software",
        descFr: "Installation et configuration de CRM à distance, CRM local ou virtuel",
        descEn: "Installation and configuration of CRM remotely, local CRM, or virtual CRM",
        icon: faTools,
    },
    {
        titleFr: "Support Technique",
        titleEn: "Technical Support",
        descFr: "Support technique joignable et réactif",
        descEn: "Accessible and responsive technical support",
        icon: faLifeRing,
    },
    {
        titleFr: "Leads Génération",
        titleEn: "Lead Generation",
        descFr: "Campagnes de génération des leads frais sur les réseaux sociaux, et sur Google",
        descEn: "Fresh Lead Generation Campaigns on Social Networks and Google",
        icon: faBullhorn,
    },
    {
        titleFr: "AI Solution",
        titleEn: "AI Solution",
        descFr: "Robotisation de démarchage téléphonique",
        descEn: "Automation of Phone Outreach",
        icon: faRobot,
    },
]

export default services;